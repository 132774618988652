import { baseAxios } from "../index";

export async function getAllPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}&tujuanPenggunaan=${data.tujuanPenggunaan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairan(dispatch, data, history) {
  // console.log(data, "SSSS");
  try {
    const response = await baseAxios.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}&tujuanPenggunaan=${data.tujuanPenggunaan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response, "LLL");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPencairan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getTotalPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUser(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getTargetUserLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function revisiPengajuan(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/revisiPengajuanDariAtasan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanBPD(data) {
  console.log(data, "KKKK");
  try {
    const response = await baseAxios.post("webApiBpd/pengajuan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    console.log(response, "DARI FUNCTIOn");
    return response;
  } catch (err) {
    console.log(err, "ERROORR");
    return err.response;
  }
}

export async function cekStatusPengajuan(data) {
  try {
    const response = await baseAxios.post("webApiBpd/cekStatusManual", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    // console.log(response, "RESPONSEEE");
    return response;
  } catch (err) {
    // console.log(err.response, "errorrrr");
    return err.response;
  }
}

export async function updateStatusManual(data) {
  try {
    const response = await baseAxios.post(
      "webApiBpd/updateStatusManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllJson(dispatch) {
  try {
    const response = await baseAxios.get("mobileListJson/allJson", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_JSON", data: response });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cekUpdateDataBpd(data) {
  console.log(data, "DATA");
  try {
    const response = await baseAxios.post("webApiBpd/cekUpdateDataBpd", data, {
      // headers: {
      //   token: localStorage.getItem("token"),
      // },
    });
    console.log(response, "response");
    return response;
  } catch (err) {
    return err.response;
  }
}

// export async function getAllJson(dispatch, data, history) {
//   try {
//     const response = await baseAxios.get(`mobileListJson/allJson`, {
//       headers: { token: localStorage.getItem("token") },
//     });
//     dispatch({ type: "SET_ALL_JSON", data: response.data });
//     console.log(response.data, "LLLLL");
//     return response.status;
//   } catch (err) {
//     return err.response;
//   }
// }
